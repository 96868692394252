import PropTypes from "prop-types";
import React from "react";

import { Link } from "gatsby";
import Header from "./header";

function Layout({ children }) {
  return (
    <div className="antialiased">
      <Header />

      <main className="">
        {children}
      </main>
      {
        /*
        <footer className="bg-blue-700">
        <nav className="flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
          <p className="text-white">
            Created by{` `}
            <a
              className="font-bold no-underline"
              href="https://bryant.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Taylor Bryant
            </a>
          </p>

          <p>
            <a
              className="font-bold text-white no-underline"
              href="https://github.com/taylorbryant/gatsby-starter-tailwind"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
          </p>
        </nav>
      </footer>
        */
      }
      <footer className="container mx-auto px-8 sm:px-32 lg:px-32 py-24">
        <footer className="relative bg-white pt-16 pb-6">

          <div className="container mx-auto">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12">
                <h4 className="text-3xl font-semibold">
                  Forest
              </h4>
                <h5 className="text-lg mt-0 mb-2 text-gray-700">
                  Organise and manage your tabs in a tree-like hierarchical structure.
              </h5>
                <div className="mt-6 mb-8 xs:mb-0">
                  <a
                    className=" group mr-4 inline-block"
                    href="https://www.reddit.com/r/treely/"
                  >
                    <svg className="text-gray-800 h-6 w-6 fill-current group-hover:text-black" viewBox="0 0 24 24">
                      <path d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z" />
                    </svg>
                  </a>
                  <a
                    className=" group mr-4 inline-block"
                    href="https://twitter.com/getforestHQ"
                  >
                    <svg className="text-gray-800 h-6 w-6 fill-current group-hover:text-black" viewBox="0 0 512 512">
                      <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="w-full lg:w-6/12">
                <div className="flex justify-end items-top mb-6">
                  <div className="w-full lg:w-4/12 pl-0 sm:pl-8">
                    <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                      Support
                  </span>
                    <ul className="list-unstyled">
                      <li className={""}>
                        <Link
                          className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                          key={"Terms & Conditions"}
                          to={"/terms"}
                        >
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                          key={"Privacy Policy"}
                          to={"/privacy"}
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                          key={"Contact Us"}
                          to={"/contact"}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
            <hr className="my-6 border-gray-400" />
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                <div className="text-sm text-gray-600 font-semibold py-1">
                  Copyright © {new Date().getFullYear()}{" "}<span className={"font-black"}>Forest</span>
                </div>
              </div>
            </div>
          </div>
        </footer>




      </footer>

    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

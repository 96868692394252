import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import Treely from "../images/treely.svg";
function Header() {
  //const [isExpanded, toggleExpansion] = useState(false);

  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    /*
    <header className="bg-teal-700">
      <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
        <Link to="/">
          <h1 className="flex items-center text-black no-underline">
            <svg
              className="w-8 h-8 mr-2 fill-current"
              height="54"
              viewBox="0 0 54 54"
              width="54"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
            </svg>
            <span className="text-xl font-bold tracking-tight">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 text-black border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/about`,
              title: `About`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-black no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>*/

    <header>
      {
        /*
        <nav
    className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
      className="container px-4 mx-auto flex flex-wrap items-center justify-between lg:px-32"
    >
      <div
        className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <a
          className="text-2xl font-bold leading-relaxed inline-block py-2 whitespace-no-wrap uppercase text-black"
          href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
        >
          <img
            src={Treely}
            className="h-4 inline-block align-baseline"
          />
          {site.siteMetadata.title}
        </a
        >
      </div>
      <div
        className="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none"
        id="example-collapse-navbar"
      >

        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
      <li className="flex items-center">
        <button className="group bg-white shadow-md px-6 py-2 rounded-lg inline-flex items-center space-x-4 hover:bg-gray-900">
          <div className="text">
            <p className=" text-xs font-semibold text-black group-hover:text-white">Download</p>
          </div>
        </button>
      </li>
            </ul>
          </div >
        </div >
            */
      }

      <nav className="bg-white">
        <div className="relative bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="md:hidden">TREELY is now Forest!</span>
                <span className="hidden md:inline">Big news! We&apos;re excited to announce that Treely is now Forest.</span>
                <span className="block sm:ml-2 sm:inline-block">
                  {/* <a href="#" className="text-white font-bold underline">
                    {' '}
                Learn more <span aria-hidden="true">&rarr;</span>
                  </a> */}
                </span>
              </p>
            </div>

          </div>
        </div>
        <div className="container mx-auto px-6 xs:px-16 sm:px-32 pt-8 flex justify-between items-center">
          <div className="flex justify-between items-center">
            <div>
              <Link
                className="text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700"
                key={"Home"}
                to={"/"}
              >
                <img
                  src={Treely}
                  className="h-3 sm:h-4 inline-block align-baseline pr-1"
                />
                {site.siteMetadata.title}
              </Link>

            </div>

          </div>

          <div className="flex items-center">
            <div className="flex">

              <a className="text-sm text-gray-700 font-medium hover:text-indigo-500 md:my-0" href="#">
                <button className="group bg-white shadow-md px-6 py-2 rounded-lg inline-flex items-center space-x-4 hover:bg-gray-900">
                  <div className="text">
                    <a
                      className=" text-xs font-semibold text-black group-hover:text-white"
                      href="https://chrome.google.com/webstore/detail/treely-tree-style-tab-man/hbledhepdppepjnbnohiepcpcnphimdj"
                    >
                      Download
                    </a>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>

  );
}

export default Header;
